<!-- 用户编辑弹窗 -->
<template>
  <div class="ele-body">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <!--      等级-->
        <el-form-item label="等级" prop="level_id">
          <el-select :span="18" v-model="ruleForm.level_id" placeholder="请选择等级">
            <el-option :span="18"
                       v-for="item in roleList"
                       :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <!--分数-->
        <el-form-item label="分数" prop="score">
          <el-col :span="18">
            <el-input v-model.number="ruleForm.score" placeholder="请输入分数"></el-input>
          </el-col>
        </el-form-item>
        <!--分数-->
        <el-form-item label="答题时长" prop="answerTime">
          <el-col :span="18">
            <el-input v-model.number="ruleForm.answerTime" placeholder="请输入答题时长"></el-input>
          </el-col>
        </el-form-item>

        <!--选项-->
        <div class="form_add_problem" v-for="(item,index) in ruleForm.domains" :key="item.key">
          <!--问题-->
          <el-form-item label="问题"
                        :prop="'domains.' + index + '.problem'"
                        :rules="{required: true, message: '问题不能为空', trigger: 'blur'}">
            <el-col :span="18">
              <el-input v-model="item.problem" placeholder="请输入问题"></el-input>
            </el-col>

          </el-form-item>
          <el-form-item label="分数"
                        :prop="'domains.' + index + '.score'"
                        :rules="{required: true, message: '分数不能为空', trigger: 'blur'}">
            <el-col :span="18">
              <el-input v-model="item.score" placeholder="请输入分数"></el-input>
            </el-col>
          </el-form-item>
<!--          图片-->
          <el-form-item label="图片" prop="image">
            <el-upload
              class="avatar-uploader"
              :action="$http.defaults.baseURL+'upload/image/module_upload/image'"
              :show-file-list="true"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              list-type="picture-card"
            >
              <i class="el-icon-plus"></i>
              <!--<img v-if="ruleForm.image" :src="$baseImgUrl+image"  v-model="ruleForm.image" class="avatar">-->
<!--              <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
            </el-upload>
          </el-form-item>
        </div>
        <!--提交      -->


      </el-form>
      <div slot="footer" style="text-align: right">
        <el-button
          @click="closeThis">关闭
        </el-button>
        <el-button
          type="primary"
          @click="save"
          :loading="loading">保存
        </el-button>
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 表单数据
      init: {},
      // 表单验证规则domains
      ruleForm: Object.assign({
        level_id: '',
        score: '',
        answerTime: '',
        content: '',
        // 问题 - 选项
        domains: [
          {
            problem: '',
            score: '',
          },
        ],
      }, this.data),
      // 预览图地址
      image: '',
      // 等级下拉数据
      gradeSelection: [],
      rules: {
        level_id: [
          {required: true, message: '选择等级', trigger: 'change'}
        ],
        score: [
          {required: true, message: '请输入分数', trigger: 'blur'}
        ],
        answerTime: [
          {required: true, message: '请选择答题时长', trigger: 'blur'}
        ],
        image: [
          {required: true, message: '请上传图片', trigger: 'blur'}
        ]

      },
      // 提交状态
      loading: false,
      // 角色列表
      roleList: [],
    }
  },
  mounted() {
    this.queryRoles();  // 查询角色列表
  },
  methods: {
    // 上传成功
    handleAvatarSuccess(res, file, fileList) {
      //console.log(fileList)
      // $http.defaults.baseURL
      // this.$set(this.form, 'image', URL.createObjectURL(file.raw))  // 实现响应式 => 数据改变视图立即更新
      //this.$set(this.ruleForm, 'image', '/'+process.env.VUE_APP_BASE_URL + res.data.path)  // 实现响应式 => 数据改变视图立即更新
      //this.image = process.env.VUE_APP_BASE_URL + res.data.path
    },
    // 上传之前的回调
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    /* 保存编辑 */
    save() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http[this.isUpdate ? 'put' : 'post'](this.isUpdate ? '/Admin/readActual/' + this.ruleForm.id : '/Admin/readActual', this.ruleForm).then(res => {
            this.loading = false;
            if (res.data.code === 200) {
              this.$message({type: 'success', message: res.data.msg});
              if (!this.isUpdate) {
                this.form = {};
              }
              this.updateVisible(false);
              this.$emit('done');
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },
    /* 查询等级列表 */
    queryRoles() {
      this.$http.get('/Admin/level').then(res => {
        if (res.data.code === 200) {
          this.roleList = res.data.data;
          //console.log(this.roleList)
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        this.$message.error(e.message);
      });
    },
    // 关闭当前页
    closeThis(){
      this.$store.dispatch('user/tabRemove', this.$route.fullPath).then(last => {
        this.$router.push(last === -1 ? '/' : this.$store.state.user.tabs[last].path);
      });
    }
  }
}
</script>

<style scoped>
.ele-body{
  background: #fff;
}
</style>
